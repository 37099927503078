<template>
    <div @click.stop>
        <v-btn
            @click.stop="showPanel = !showPanel"
            :block="block"
            :class="buttonClass"
            :height="inline ? 'null' : height"
            :icon="hasIcon && label == null"
            :large="large"
            :small="small"
            :title="title">
            <v-icon v-if="hasIcon && label == null" :large="large" :small="small">{{ leftIcon || rightIcon }}</v-icon>
            <v-row v-else-if="!inline" class="mt-0 mb-3">
                <v-col v-if="leftIcon != null" class="mx-0 mx-auto px-auto" cols="12">
                    <v-icon :large="large" :small="small">{{ leftIcon || rightIcon }}</v-icon>
                </v-col>
                <v-col v-if="label != null" class="ma-0 pa-0" cols="12">
                    <div>{{ label }}</div>
                </v-col>
            </v-row>
            <span v-else>
                <v-icon v-if="leftIcon != null" :large="large" :small="small" :left="label != null">{{ leftIcon }}</v-icon>
                {{ label }}
                <v-icon v-if="rightIcon != null" :large="large" :small="small" :right="label != null">{{ rightIcon }}</v-icon>
            </span>
        </v-btn>

        <v-navigation-drawer
            v-model="showPanel"
            app
            :bottom="bottom"
            clipped
            :hide-overlay="hideOverlay"
            right
            style="height: 100%;"
            :width="navWidth">
            <v-toolbar dense>
                <v-btn
                    icon
                    small
                    title="Close"
                    @click="showPanel = false">
                    <v-icon>{{ ($vuetify.breakpoint.mobile && bottom) ? 'mdi-arrow-down' : 'mdi-arrow-right' }}</v-icon>
                </v-btn>
                <v-toolbar-title>{{ label }}</v-toolbar-title>
                <v-spacer />
                <v-btn
                    v-if="canRefresh"
                    icon
                    small
                    title="Refresh"
                    @click="refresh">
                    <v-icon small>mdi-refresh</v-icon>
                </v-btn>
            </v-toolbar>

            <BT-List
                :canDelete="false"
                dense
                hideFooter
                hideTableHeader
                isSimple
                :navigation="navigation"
                :onPullSuccessAsync="pullMappings"
                :params="cParams"
                :refreshToggle="refreshToggle">
                <template slot-scope="{ item }">
                    <v-list-item-content>
                        <v-list-item-subtitle>
                            <BT-Entity
                                v-if="externalProperty != null"
                                navigation="products"
                                :itemValue="item.data.itemID"
                                itemText="productName" />
                            <span v-else>{{ item.data.externalProperty }}</span>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content>
                        <v-list-item-subtitle>
                            <v-text-field
                                hide-details
                                v-model="item.data.externalValue"
                                :disabled="!isEditing || !$canEdit(navigation)"
                                label="value"
                                :loading="item.isLoading"
                                @change="updateTracking(item)" />
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </template>
            </BT-List>

            <!-- <v-overlay :value="loadingMsg != null" absolute key="6" class="text-center">
                <v-progress-circular indeterminate size="32" />
                <p>{{ loadingMsg }}</p>
            </v-overlay> -->
        </v-navigation-drawer>
    </div>
</template>

<script>
export default {
    name: 'BT-Sidebar-Mappings',
    data: function() {
        return {
            itemList: null,
            refreshToggle: false,
            showPanel: false,
        }
    },
    props: {
        navigation: null,
        block: {
            type: Boolean,
            default: false
        },
        bottom: {
            type: Boolean,
            default: true
        },
        buttonClass: {
            type: String,
            default: 'ma-1'
        },
        canRefresh: {
            type: Boolean,
            default: true,
        },
        externalParty: {
            type: String,
            default: 'xero'
        },
        externalProperty: {
            type: String,
            default: null
        },
        externalPropertyOptions: {
            type: Array,
            default: null
        },
        externalPropertyOptionSet: {
            type: String,
            default: 'xero'
        },
        hideOverlay: {
            type: Boolean,
            default: true
        },
        inline: {
            type: Boolean,
            default: false
        },
        isEditing: {
            type: Boolean,
            default: false
        },
        itemID: {
            type: String,
            default: null
        },
        itemType: {
            type: String,
            default: 'Product'
        },
        label: {
            type: String,
            default: null
        },
        large: {
            type: Boolean,
            default: false
        },
        leftIcon: {
            type: String,
            default: null
        },
        navWidth: {
            type: String,
            default: '400'
        },
        rightIcon: {
            type: String,
            default: null
        },
        small: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: null
        }
    },
    computed: {
        cPropertyOptions() {
            if (this.externalPropertyOptions != null && this.externalPropertyOptions.length > 0) {
                return this.externalPropertyOptions;
            }
            else if (this.externalPropertyOptionSet != null) {
                if (this.externalPropertyOptionSet == 'xero') {
                    return ['Description', 'TaxType', 'ItemCode', 'AccountCode', 'TrackingCategoryNameOne', 'TrackingCategoryOptionOne', 'TrackingCategoryNameTwo', 'TrackingCategoryOptionTwo'];
                }
            }
            return null;   
        },
        cParams() {
            if (this.externalProperty != null) {
                return { query: `ItemType=${this.itemType} ANDALSO ExternalPartyID=${this.externalParty} ANDALSO ExternalProperty=${this.externalProperty}` };
            }
            else {
                return { query: `ItemType=${this.itemType} ANDALSO ExternalPartyID=${this.externalParty} ANDALSO ItemID=${this.itemID}` };
            }
        },
        hasIcon() {
            return this.leftIcon != null || this.rightIcon != null;
        },
        height() {
            return this.small ? 30 : (this.large ? 80 : 'auto');
        }
    },
    methods: {
        cFilter(list) {
            var rList = [];
            var self = this;

            if (this.cPropertyOptions == null) {
                rList = list.map(x => { return { isChanged: false, isLoading: false, original: JSON.stringify(x), data: self.copyDeep(x) }})
            }
            else {
                this.cPropertyOptions.forEach(prop => {
                    var eItem = list.find(x => x.externalProperty == prop);
                    if (eItem == null) {
                        eItem = {
                            id: null,
                            rowVersion: null,
                            externalPartyID: this.externalParty,
                            externalProperty: prop,
                            externalValue: '',
                            itemType: this.itemType,
                            itemID: this.itemID,
                        };
                    }

                    rList.push({
                        data: this.copyDeep(eItem),
                        original: JSON.stringify(eItem),
                        isLoading: false,
                        isChanged: false
                    });
                });
            }

            this.itemList = rList;

            return rList;
        },
        async pullMappings(mappings) {
            console.log('pulling mappings');
            console.log(mappings);

            if (this.externalProperty != null) {
                var products = await this.$BlitzIt.store.getAll('products');

                return products.map(x => {
                    var eMap = mappings.find(m => m.itemID == x.id);

                    return {
                        id: eMap != null ? eMap.id : null,
                        rowVersion: eMap != null ? eMap.rowVersion : null,
                        externalPartyID: eMap != null ? eMap.externalPartyID : this.externalParty,
                        externalProperty: eMap != null ? eMap.externalProperty : this.externalProperty,
                        externalValue: eMap != null ? eMap.externalValue : null,
                        itemType: eMap != null ? eMap.itemType : this.itemType,
                        itemID: eMap != null ? eMap.itemID : x.id
                    }
                })
                .map(x => { 
                    return { 
                        isChanged: false, 
                        isLoading: false, 
                        original: JSON.stringify(x), 
                        data: this.copyDeep(x) 
                    }
                });
            }
            else if (this.itemID != null) {
                return this.cPropertyOptions.map(prop => {
                    var eMap = mappings.find(x => x.externalProperty == prop);
console.log(eMap);
                    var i = {
                        id: eMap != null ? eMap.id : null,
                        rowVersion: eMap != null ? eMap.rowVersion : null,
                        externalPartyID: eMap != null ? eMap.externalPartyID : this.externalParty,
                        externalProperty: eMap != null ? eMap.externalProperty : prop,
                        externalValue: eMap != null ? eMap.externalValue : null,
                        itemType: eMap != null ? eMap.itemType : this.itemType,
                        itemID: eMap != null ? eMap.itemID : this.itemID
                    }
                    console.log(i);
                    return i;
                })
                .map(x => { 
                    return { 
                        isChanged: false, 
                        isLoading: false, 
                        original: JSON.stringify(x), 
                        data: this.copyDeep(x) 
                    }
                });
            }

            return [];
        },
        updateTracking(item) {
            //item.isChanged = item.original != JSON.stringify(item.data);
            if (item.original != JSON.stringify(item.data)) {
                //save
                this.saveMapping(item);
            }
        },
        refresh() {
            this.refreshToggle = !this.refreshToggle;
        },
        saveMapping(mapping) {
            mapping.isLoading = true;

            console.log('mapping saving');
            console.log(mapping);
            
            if (mapping.data.externalValue == null || mapping.data.externalValue.length == 0) {
                console.log('mapping deleting');
                this.$BlitzIt.api.delete(this.navigation, mapping.data)
                    .then(() => {
                        mapping.data.rowVersion = null;
                        mapping.data.id = null;
                        mapping.original = JSON.stringify(mapping.data);
                        mapping.isChanged = false;
                    })
                    .finally(() => { mapping.isLoading = false; });
            }
            else if (mapping.data.id == null) {
                console.log('mapping adding');
                this.$BlitzIt.api.post(this.navigation, mapping.data)
                    .then(res => {
                        mapping.original = JSON.stringify(res.data.data);
                        mapping.data = res.data.data;
                        mapping.isChanged = false;
                    })
                    .finally(() => { mapping.isLoading = false; })
            }
            else {
                console.log('mapping patching');
                this.$BlitzIt.api.patch(this.navigation, mapping.data)
                    .then(res => {
                        console.log(res);
                        mapping.original = JSON.stringify(res.data.data);
                        mapping.data = res.data.data;
                        mapping.isChanged = false;
                    })
                    .finally(() => { mapping.isLoading = false; })
            }
        }
    }
}
</script>